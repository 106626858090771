<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="user-edit-tab-info">

    <!-- Avatar Row -->
    <div class="vx-row" >
      <div class="md:w-1/2 w-full" >
        <div class="flex items-start flex-col sm:flex-row" id="ava" style="position:relative;">
          <!-- <img :src="data_local.profileImageUrl" class="mr-8 rounded h-24 w-24" /> -->

                <div class="img-wrapper" v-if="data_local.profileImageUrl">
                  <img :src="data_local.profileImageUrl" class="img-fluid imgfit" alt />
                </div>

                <div class="img-wrapper" v-else>
                  <img src="https://freshclinics-bucket.s3.ap-southeast-2.amazonaws.com/undefined/k3r1nm7bpf921595963499155.png" class="img-fluid imgfit" alt />
                </div>
          <!-- <vs-avatar v-if="data_local.profileImageUrl" :src="data_local.profileImageUrl" size="110px" class="mr-4 imgfit" /> -->
          <!-- <vs-avatar v-else src="https://freshclinics-bucket.s3.ap-southeast-2.amazonaws.com/undefined/k3r1nm7bpf921595963499155.png" size="100px" class="mr-4" /> -->
          <div>
            <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">{{ data.name  }}</p>
            <input type="file" class="hidden" ref="update_avatar_input" @change="update_avatar" accept="image/*">

            <!-- Toggle comment of below buttons as one for actual flow & currently shown is only for demo -->
            <!-- <vs-button class="mr-4 mb-4">Change Avatar</vs-button> -->
            <vs-button type="border" class="mr-4" @click="$refs.update_avatar_input.click()">Change Avatar</vs-button>

            <vs-button type="border" color="danger" @click="data_local.profileImageUrl=null">Remove Avatar</vs-button>
          </div>
        </div>

      </div>
      <div class="vx-col md:w-1/2 w-full">
      <label class="vs-input--label">WWCC Verified</label>
      <vs-switch  class="" v-model="data_local.isWWCCVerified" v-validate="'required'" name="isWWCCVerified" @click="changeWWCCStatus(data_local.isWWCCVerified)"/>

      <span class="text-danger text-sm"  v-show="errors.has('isWWCCVerified')">{{ errors.first('isWWCCVerified') }}</span>

      </div>

    </div>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mt-4" label="First Name" v-model="data_local.firstName" v-validate="'required'" name="firstName" data-vv-as="first name" />
        <span class="text-danger text-sm"  v-show="errors.has('firstName')">{{ errors.first('firstName') }}</span>

        <vs-input class="w-full mt-4" label="Last Name" v-model="data_local.lastName" v-validate="'required'" name="lastName" data-vv-as="last name"/>
        <span class="text-danger text-sm"  v-show="errors.has('lastName')">{{ errors.first('lastName') }}</span>

        <vs-input class="w-full mt-4" :disabled="!data_local.newUser" label="Email" v-model="data_local.email" type="email" v-validate="'required|email'" name="email" data-vv-as="email"/>
        <span class="text-danger text-sm"   v-show="errors.has('email')">{{ errors.first('email') }}</span>
      <div v-if="data_local && data_local.newUser">
        <vs-input class="w-full mt-4" label="Password" v-model="data_local.password"
                        v-validate="{
                          required: true,
                          min: 8,
                          regex: /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9._*\W]+$)/
                        }"
                        name="password"
                        data-vv-as="password"
                        icon-no-border
                        icon-pack="feather"
                        :icon="passwordIcon"
                        :type="passwordType"
                        autocomplete="new-password"
                        @click.native="showHidePassword($event)"/>
        <span class="text-danger text-sm"  v-show="errors.has('password')">{{ errors.first('password') }}</span>
      </div>
          <div class="mt-4 w-full googleAutocomplete vs-con-input">
            <label class="vs-input--label">Tutoring Location</label>
            <vue-google-autocomplete
            v-model="localAddressData"
            id="googleAutocomplete"
            v-validate="''"
            name="tutoringLocation"
            data-vv-validate-on="blur"
            data-vv-as="tutoring location"
            :country="['au']"
            classname="w-full vs-inputx vs-input--input normal"
            placeholder=""
            v-on:placechanged="getAddressData"
            v-on:keypress="emptyAddressData"
            >
            </vue-google-autocomplete>
          </div>

            <span class="text-danger text-sm" v-show="errors.has('tutoringLocation')">{{ errors.first('tutoringLocation') }}</span>

        <vs-input class="w-full mt-4" label="Rate ($)" v-model="data_local.rate" v-validate="'required|decimal:2|min_value:10|max_value:500'" name="rate" />
        <span class="text-danger text-sm"  v-show="errors.has('rate')">{{ errors.first('rate') }}</span>


      </div>

      <div class="vx-col md:w-1/2 w-full">

        <div class="mt-4">
          <label class="vs-input--label">Status</label>
          <v-select v-model="selectedStatus" :clearable="false" :reduce="label => label.value" :options="statusOptions" v-validate="'required'" name="status" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div>

          <div class="mt-4">
            <label class="vs-input--label">Date of Birth</label>
            <flat-pickr v-model="data_local.dateOfBirth" :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', maxDate: currentDate, altInputClass:'w-full flatpickr-input'}" class="w-full" v-validate="'required'" data-vv-as="date of birth" name="dateOfBirth" />
            <span class="text-danger text-sm"  v-show="errors.has('dateOfBirth')">{{ errors.first('dateOfBirth') }}</span>
          </div>
        <vs-input class="w-full mt-4" label="Contact number" v-model="data_local.contactNumber"
      v-validate="{
        required: true,
        regex: /^\({0,1}((0|\+61)(2|3|4|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/
      }" name="contactNumber" data-vv-as="contact number"/>
        <span class="text-danger text-sm"  v-show="errors.has('contactNumber')">{{ errors.first('contactNumber') }}</span>


        <!-- <div class="mt-4">
          <label class="vs-input--label">Languages</label>
          <v-select multiple  v-model="selectedLanguages" :clearable="false" label="name" :options="languagesOptions" v-validate="'required'" data-vv-validate-on="blur" name="language" />
          <span class="text-danger text-sm"  v-show="errors.has('language')">{{ errors.first('language') }}</span>
        </div> -->
<div class="mt-4 vs-con-input w-full">
          <label class="vs-input--label">Languages</label>
          <treeselect
            search-nested
            class="w-full   normal"
            :flat="true"
            :maxHeight="100"
            v-model="selectedLanguages"
            :multiple="true"
            :options="languagesOptions"
            :normalizer="normalizer"
            v-validate="'required'" data-vv-validate-on="blur" name="language">
          </treeselect>
          <span class="text-danger text-sm"  v-show="errors.has('language')">{{ errors.first('language') }}</span>

</div>
<div class="mt-4 vs-con-input w-full">
          <label class="vs-input--label">Subjects</label>
          <treeselect
            search-nested
            class="w-full   normal"
            :flat="true"
            :maxHeight="100"
            v-model="selectedSubjects"
            :multiple="true"
            :options="subjectsOptions"
            :normalizer="normalizer"
            v-validate="'required'" data-vv-validate-on="blur" name="subject">
            <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
               {{ node.label }}
              <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
            </label>
          </treeselect>
          <span class="text-danger text-sm"  v-show="errors.has('subject')">{{ errors.first('subject') }}</span>

</div>
        <vs-input class="w-full mt-4" label="WWCC" v-model="data_local.wwcc" v-validate="{
        required: true,
        min: 7,
        max:12
      }" name="wwcc" data-vv-as="wwcc" />
        <span class="text-danger text-sm"  v-show="errors.has('wwcc')">{{ errors.first('wwcc') }}</span>






        <!-- <div class="mt-4">
          <label class="vs-input--label">Subjects</label>
          <v-select multiple v-model="selectedSubjects"
          :clearable="false" label="name"
          :options="subjectsOptions"
          :selectable="option => ! option.name.includes(data_local.subjects.name)"
           v-validate="'required'" data-vv-validate-on="blur" name="subject" />
          <span class="text-danger text-sm"  v-show="errors.has('subject')">{{ errors.first('subject') }}</span>
        </div> -->
      </div>
    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save_changes" >Save Changes</vs-button>
          <!-- <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data">Reset</vs-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import axios from "@/axios";
 // import the component
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: {
    flatPickr,
    vSelect,
    VueGoogleAutocomplete,
    Treeselect
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentDate: new Date(),
      ageLimit: new Date(),
      newAddressData: {
        displayAddress: '',
        placeName: '',
        state: '',
        postCode: '',
        location: {
          type: "Point",
          coordinates: []
        }
      },

    normalizer(node) {
      return {
        id: node._id,
        label: node.name,
        children: node.children,
      }
    },
      activeLoading:false,
      avatar: null,
      data_local: JSON.parse(JSON.stringify(this.data)),
      global_data: this.data,
      localAddressData: '',
      languagesOptions: [],
      subjectsOptions: [],
      selectedStatus: 'Enabled',
      selectedLanguages: [],
      selectedSubjects: [],
      statusOptions: [
        { label: "Enabled",  value: "Enabled" },
        { label: "Disabled",  value: "Disabled" },
      ],
      showPassword: false,
      passwordType: 'password',
      passwordIcon: 'icon icon-eye-off'
    }
  },
  computed: {
    status_local: {
      get() {
        return { label: this.capitalize(this.data_local.status),  value: this.data_local.status  }
      },
      set(obj) {
        this.data_local.status = obj.value
      }
    },
    role_local: {
      get() {
        return { label: this.capitalize(this.data_local.role),  value: this.data_local.role  }
      },
      set(obj) {
        this.data_local.role = obj.value
      }
    },
    validateForm() {
      return !this.errors.any()
    }
  },
  methods: {
    ...mapActions('tutor', ['updateTutorById','getLanguages','getSubjects']),
    ...mapMutations('tutor',['SET_USER_ID']),
    capitalize(str) {
      return str.slice(0,1).toUpperCase() + str.slice(1,str.length)
    },
    showHidePassword(event){
      if(event.target.className === "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border"
        || event.target.className === "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border")
      {
        this.showPassword = !this.showPassword
        this.passwordType === 'password' ? this.passwordType='text' : this.passwordType='password'
        this.passwordIcon === 'icon icon-eye-off' ? this.passwordIcon='icon icon-eye' : this.passwordIcon='icon icon-eye-off'
      }
      return true
    },
    emptyAddressData(){
      this.newAddressData = {
        displayAddress: '',
        placeName: '',
        state: '',
        postCode: '',
        location: {
          type: "Point",
          coordinates: []
        }
      }
    },
    changeWWCCStatus(){
      if(!this.data_local.newUser) {
        this.activeLoading = true
        this.$vs.loading({
          type: 'border',
        })
        let tutorId = this.$route.params.tutorId
        let tutorData={ isWWCCVerified :this.data_local.isWWCCVerified ? false : true }
        this.updateTutorById({tutorId, tutorData}).then(res => {
          this.$store.commit('tutor/SET_USER_ID', res.data.data._id)
          this.$router.replace({ name: 'EditTutorDetails', params: { tutorId: res.data.data._id } })
          this.activeLoading = false
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Update',
            text: res.data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success'
          })
        }).catch(err => {
          this.activeLoading = false
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Failure',
            text: 'Something went wrong.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })

      }
      return true;
    },
    async save_changes() {
      let isValid = await this.$validator.validateAll()
      if(this.newAddressData.displayAddress == ''){
        this.errors.add({
          field: 'tutoringLocation',
          msg: 'Please enter valid address.'
        });
        isValid = false
      }
      if(!isValid){
        return false
      }
      let self = this
      let data_local = this.data_local
      let tutorId = this.$route.params.tutorId
      let tutorData = {
          userType: 'tutor',
          profileCreatedBy: 'tutor',
          profileImageUrl: data_local.profileImageUrl,
          firstName: data_local.firstName,
          lastName: data_local.lastName,
          fullName: data_local.firstName+' '+data_local.lastName,
          // email: data_local.email,
          rate: data_local.rate,
          status: this.selectedStatus,
          dateOfBirth: data_local.dateOfBirth,
          contactNumber: data_local.contactNumber,
          languages: this.selectedLanguages,
          subjects: this.selectedSubjects,
          wwcc: data_local.wwcc,
          isWWCCVerified: data_local.isWWCCVerified,
          tutoringLocation : self.newAddressData
      }
      if(data_local.newUser){
        tutorData.email =  data_local.email
        tutorData.isNewSignupFromCMS = true
        tutorData.password = data_local.password
      }
      this.updateTutorById({tutorId, tutorData}).then(res => {
        this.$store.commit('tutor/SET_USER_ID', res.data.data._id)
        this.$router.replace({ name: 'EditTutorDetails', params: { tutorId: res.data.data._id } })
        this.$vs.notify({
          title: 'Update',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      }).catch(err => {
        console.log(err.response);
        if(err.response.status === 409){
          this.$vs.notify({
            title: 'Failure',
            text: err.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
        else{
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
          }
      })
    },
    reset_data() {
      this.data_local = JSON.parse(JSON.stringify(this.data))
    },
    getAddressData(address, detailedAddress){
        this.newAddressData.displayAddress = detailedAddress.formatted_address
        this.localAddressData = detailedAddress.formatted_address
        this.newAddressData.placeName = detailedAddress.name
        this.newAddressData.state = address.administrative_area_level_1
        this.newAddressData.postCode = address.postal_code
        this.newAddressData.location.type = "Point"
        this.newAddressData.location.coordinates[0] = address.longitude
        this.newAddressData.location.coordinates[1] = address.latitude
    },
    update_avatar() {
      // You can update avatar Here
      // For reference you can check dataList example
      // We haven't integrated it here, because data isn't saved in DB
      this.$vs.loading({
        text: "Uploading Profile Picture...",
        color: "#3dc9b3",
        background: "white",
        container: "#ava",
        scale: 0.6
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png"
        }
      };
      axios
        .post("/api/v1/admin/profileUpdateFileUpload", data, config)
        .then(res => {
          self.data_local.profileImageUrl = res.data.Location
          this.$vs.loading.close("#ava > .con-vs-loading");
        })
        .catch(err => {
          this.$vs.loading.close("#ava > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        });
    },
    autoCompleteOnFocus(){
      // document.getElementsByClassName('googleAutocomplete').addClass('isFocus')
    }
  },
  mounted(){
    this.currentDate = this.currentDate.setFullYear(this.currentDate.getFullYear()-18)
    this.getLanguages().then(res => {
      this.languagesOptions = res.data.data.docs
      this.selectedLanguages = this.data_local.languages.map(function(language){return language._id})
    })
    this.getSubjects().then(res => {
      this.subjectsOptions = res.data.data.docs.map(function(subject){
        if(subject.children && subject.children.length>0){
          subject.isDisabled = true
        }
        return subject
      })
    this.selectedSubjects = this.data_local.subjects.map(function(subject){return subject._id})
    })
    this.selectedStatus = this.data_local.status
    this.localAddressData = this.data_local.tutoringLocation.displayAddress
    this.newAddressData = this.data_local.tutoringLocation


  }
}
</script>
<style scoped>
  .imgfit {
    height : 110px;
    width: 110px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 10px;
    object-fit: cover;
  }
</style>
