<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="user-edit-tab-info">
    <div class="vx-row">
      <div class="vx-col md:w-1/3 w-full">

        <div class="mt-4">
          <label class="vs-input--label">TimeZone</label>
          <v-select v-model="data_local.availabilitiesTimeZone" :clearable="false" :reduce="label => label.value" :options="timeZoneOptions" v-validate="'required'" name="availabilitiesTimeZone" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('availabilitiesTimeZone')">{{ errors.first('availabilitiesTimeZone') }}</span>
        </div>
      </div>
    </div>
    <div class="vx-row">

      <!-- Col 1 -->
      <div class="vx-col w-full md:w-2/3">

      <!-- new dayy 0 -->
    <div class="mt-4">
        <label class="vs-input--label">{{data_local.availabilities[0].day | capitalize}}</label>
        <div class="vx-row">
          <div class="vx-col w-full">
              <div class="vx-row mt-2" v-for="(availableTime, index) in data_local.availabilities[0].availableTimes" :key="index">
                  <div class="vx-col w-2/5">
                    <flat-pickr @on-close="timeChangeEvent(0,index,0)" :ref="'beforeTarget'+index+data_local.availabilities[0].day" placeholder="Start Time" v-model="availableTime.startTime"  :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                     v-validate="{ required : availableTime.endTime ? true : false }" data-vv-as="start time" :name="'startTime'+index+data_local.availabilities[0].day" />
                    <span class="text-danger text-sm"  v-show="errors.has('startTime'+index+data_local.availabilities[0].day)">{{ errors.first('startTime'+index+data_local.availabilities[0].day) }}</span>
                  </div>
                  <div class="vx-col w-0.5/5 text-center">
                      -
                  </div>
                  <div class="vx-col w-2/5">
                      <flat-pickr @on-close="timeChangeEvent(0,index,1)" ref="afterTarget" v-model="availableTime.endTime" placeholder="End Time" :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                      v-validate="{ required : availableTime.startTime ? true : false, is_not: availableTime.startTime }"
                          data-vv-as="end time" :name="'endTime'+index+data_local.availabilities[0].day" />
                      <span class="text-danger text-sm"  v-show="errors.has('endTime'+index+data_local.availabilities[0].day)">{{ errors.first('endTime'+index+data_local.availabilities[0].day) }}</span>
                  </div>

          <div v-if="index==0" class="vx-col w-.5/5">
              <vs-button type="border" class="ml-auto" @click="addNewTimeSlot(0)" :disabled="!validateForm">+</vs-button>
          </div>

          <div v-else class="vx-col w-.5/5">
              <vs-button type="border" color="danger" class="ml-auto" @click="removeTimeSlot(0, index, 'endTime'+index+data_local.availabilities[0].day)" :disabled="!validateForm">*</vs-button>
          </div>

              </div>
          </div>
          <!-- <div class="vx-col w-full md:w-1/3">
              <vs-button type="border" class="ml-auto" @click="addNewTimeSlot(0)" :disabled="!validateForm">+</vs-button>
          </div> -->
        </div>
    </div>
    <!--enddd new dayy 0 -->

      <!-- new dayy 1 -->
    <div class="mt-4">
        <label class="vs-input--label">{{data_local.availabilities[1].day | capitalize}}</label>
        <div class="vx-row">
          <div class="vx-col w-full">
              <div class="vx-row mt-2" v-for="(availableTime, index) in data_local.availabilities[1].availableTimes" :key="index">
                  <div class="vx-col w-full md:w-2/5">
                    <flat-pickr @on-close="timeChangeEvent(1,index,0)" :ref="'beforeTarget'+index+data_local.availabilities[1].day" placeholder="Start Time" v-model="availableTime.startTime"  :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                     v-validate="{ required : availableTime.endTime ? true : false }" data-vv-as="start time" :name="'startTime'+index+data_local.availabilities[1].day" />
                    <span class="text-danger text-sm"  v-show="errors.has('startTime'+index+data_local.availabilities[1].day)">{{ errors.first('startTime'+index+data_local.availabilities[1].day) }}</span>
                  </div>
                  <div class="vx-col md:w-0.5/5 text-center">
                      -
                  </div>
                  <div class="vx-col w-full md:w-2/5">
                      <flat-pickr @on-close="timeChangeEvent(1,index,1)" ref="afterTarget" v-model="availableTime.endTime" placeholder="End Time" :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                      v-validate="{ required : availableTime.startTime ? true : false, is_not: availableTime.startTime }"
                          data-vv-as="end time" :name="'endTime'+index+data_local.availabilities[1].day" />
                      <span class="text-danger text-sm"  v-show="errors.has('endTime'+index+data_local.availabilities[1].day)">{{ errors.first('endTime'+index+data_local.availabilities[1].day) }}</span>
                  </div>

          <div v-if="index==0" class="vx-col w-.5/5">
              <vs-button type="border" class="ml-auto" @click="addNewTimeSlot(1)" :disabled="!validateForm">+</vs-button>
          </div>

          <div v-else class="vx-col w-.5/5">
              <vs-button type="border" color="danger" class="ml-auto" @click="removeTimeSlot(1, index, 'endTime'+index+data_local.availabilities[1].day)" :disabled="!validateForm">*</vs-button>
          </div>

              </div>
          </div>
        </div>
    </div>
    <!--enddd new dayy 1 -->

      <!-- new dayy 2 -->
    <div class="mt-4">
        <label class="vs-input--label">{{data_local.availabilities[2].day | capitalize}}</label>
        <div class="vx-row">
          <div class="vx-col w-full">
              <div class="vx-row mt-2" v-for="(availableTime, index) in data_local.availabilities[2].availableTimes" :key="index">
                  <div class="vx-col w-full md:w-2/5">
                    <flat-pickr @on-close="timeChangeEvent(2,index,0)" :ref="'beforeTarget'+index+data_local.availabilities[2].day" placeholder="Start Time" v-model="availableTime.startTime"  :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                     v-validate="{ required : availableTime.endTime ? true : false }" data-vv-as="start time" :name="'startTime'+index+data_local.availabilities[2].day" />
                    <span class="text-danger text-sm"  v-show="errors.has('startTime'+index+data_local.availabilities[2].day)">{{ errors.first('startTime'+index+data_local.availabilities[2].day) }}</span>
                  </div>
                  <div class="vx-col md:w-0.5/5 text-center">
                      -
                  </div>
                  <div class="vx-col w-full md:w-2/5">
                      <flat-pickr @on-close="timeChangeEvent(2,index,1)" ref="afterTarget" v-model="availableTime.endTime" placeholder="End Time" :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                      v-validate="{ required : availableTime.startTime ? true : false, is_not: availableTime.startTime }"
                          data-vv-as="end time" :name="'endTime'+index+data_local.availabilities[2].day" />
                      <span class="text-danger text-sm"  v-show="errors.has('endTime'+index+data_local.availabilities[2].day)">{{ errors.first('endTime'+index+data_local.availabilities[2].day) }}</span>
                  </div>

          <div v-if="index==0" class="vx-col w-.5/5">
              <vs-button type="border" class="ml-auto" @click="addNewTimeSlot(2)" :disabled="!validateForm">+</vs-button>
          </div>

          <div v-else class="vx-col w-.5/5">
              <vs-button type="border" color="danger" class="ml-auto" @click="removeTimeSlot(2, index, 'endTime'+index+data_local.availabilities[2].day)" :disabled="!validateForm">*</vs-button>
          </div>

              </div>
          </div>
        </div>
    </div>
    <!--enddd new dayy 2 -->

      <!-- new dayy 3 -->
    <div class="mt-4">
        <label class="vs-input--label">{{data_local.availabilities[3].day | capitalize}}</label>
        <div class="vx-row">
          <div class="vx-col w-full">
              <div class="vx-row mt-2" v-for="(availableTime, index) in data_local.availabilities[3].availableTimes" :key="index">
                  <div class="vx-col w-full md:w-2/5">
                    <flat-pickr @on-close="timeChangeEvent(3,index,0)" :ref="'beforeTarget'+index+data_local.availabilities[3].day" placeholder="Start Time" v-model="availableTime.startTime"  :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                     v-validate="{ required : availableTime.endTime ? true : false }" data-vv-as="start time" :name="'startTime'+index+data_local.availabilities[3].day" />
                    <span class="text-danger text-sm"  v-show="errors.has('startTime'+index+data_local.availabilities[3].day)">{{ errors.first('startTime'+index+data_local.availabilities[3].day) }}</span>
                  </div>
                  <div class="vx-col md:w-0.5/5 text-center">
                      -
                  </div>
                  <div class="vx-col w-full md:w-2/5">
                      <flat-pickr @on-close="timeChangeEvent(3,index,1)" ref="afterTarget" v-model="availableTime.endTime" placeholder="End Time" :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                      v-validate="{ required : availableTime.startTime ? true : false, is_not: availableTime.startTime }"
                          data-vv-as="end time" :name="'endTime'+index+data_local.availabilities[3].day" />
                      <span class="text-danger text-sm"  v-show="errors.has('endTime'+index+data_local.availabilities[3].day)">{{ errors.first('endTime'+index+data_local.availabilities[3].day) }}</span>
                  </div>

          <div v-if="index==0" class="vx-col w-.5/5">
              <vs-button type="border" class="ml-auto" @click="addNewTimeSlot(3)" :disabled="!validateForm">+</vs-button>
          </div>

          <div v-else class="vx-col w-.5/5">
              <vs-button type="border" color="danger" class="ml-auto" @click="removeTimeSlot(3, index, 'endTime'+index+data_local.availabilities[3].day)" :disabled="!validateForm">*</vs-button>
          </div>

              </div>
          </div>
        </div>
    </div>
    <!--enddd new dayy 3 -->

      <!-- new dayy 4 -->
    <div class="mt-4">
        <label class="vs-input--label">{{data_local.availabilities[4].day | capitalize}}</label>
        <div class="vx-row">
          <div class="vx-col w-full">
              <div class="vx-row mt-2" v-for="(availableTime, index) in data_local.availabilities[4].availableTimes" :key="index">
                  <div class="vx-col w-full md:w-2/5">
                    <flat-pickr @on-close="timeChangeEvent(4,index,0)" :ref="'beforeTarget'+index+data_local.availabilities[4].day" placeholder="Start Time" v-model="availableTime.startTime"  :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                     v-validate="{ required : availableTime.endTime ? true : false }" data-vv-as="start time" :name="'startTime'+index+data_local.availabilities[4].day" />
                    <span class="text-danger text-sm"  v-show="errors.has('startTime'+index+data_local.availabilities[4].day)">{{ errors.first('startTime'+index+data_local.availabilities[4].day) }}</span>
                  </div>
                  <div class="vx-col md:w-0.5/5 text-center">
                      -
                  </div>
                  <div class="vx-col w-full md:w-2/5">
                      <flat-pickr @on-close="timeChangeEvent(4,index,1)" ref="afterTarget" v-model="availableTime.endTime" placeholder="End Time" :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                      v-validate="{ required : availableTime.startTime ? true : false, is_not: availableTime.startTime }"
                          data-vv-as="end time" :name="'endTime'+index+data_local.availabilities[4].day" />
                      <span class="text-danger text-sm"  v-show="errors.has('endTime'+index+data_local.availabilities[4].day)">{{ errors.first('endTime'+index+data_local.availabilities[4].day) }}</span>
                  </div>

          <div v-if="index==0" class="vx-col w-.5/5">
              <vs-button type="border" class="ml-auto" @click="addNewTimeSlot(4)" :disabled="!validateForm">+</vs-button>
          </div>

          <div v-else class="vx-col w-.5/5">
              <vs-button type="border" color="danger" class="ml-auto" @click="removeTimeSlot(4, index, 'endTime'+index+data_local.availabilities[4].day)" :disabled="!validateForm">*</vs-button>
          </div>

              </div>
          </div>
        </div>
    </div>
    <!--enddd new dayy 4 -->

      <!-- new dayy 5 -->
    <div class="mt-4">
        <label class="vs-input--label">{{data_local.availabilities[5].day | capitalize}}</label>
        <div class="vx-row">
          <div class="vx-col w-full">
              <div class="vx-row mt-2" v-for="(availableTime, index) in data_local.availabilities[5].availableTimes" :key="index">
                  <div class="vx-col w-full md:w-2/5">
                    <flat-pickr @on-close="timeChangeEvent(5,index,0)" :ref="'beforeTarget'+index+data_local.availabilities[5].day" placeholder="Start Time" v-model="availableTime.startTime"  :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                     v-validate="{ required : availableTime.endTime ? true : false }" data-vv-as="start time" :name="'startTime'+index+data_local.availabilities[5].day" />
                    <span class="text-danger text-sm"  v-show="errors.has('startTime'+index+data_local.availabilities[5].day)">{{ errors.first('startTime'+index+data_local.availabilities[5].day) }}</span>
                  </div>
                  <div class="vx-col md:w-0.5/5 text-center">
                      -
                  </div>
                  <div class="vx-col w-full md:w-2/5">
                      <flat-pickr @on-close="timeChangeEvent(5,index,1)" ref="afterTarget" v-model="availableTime.endTime" placeholder="End Time" :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                      v-validate="{ required : availableTime.startTime ? true : false, is_not: availableTime.startTime }"
                          data-vv-as="end time" :name="'endTime'+index+data_local.availabilities[5].day" />
                      <span class="text-danger text-sm"  v-show="errors.has('endTime'+index+data_local.availabilities[5].day)">{{ errors.first('endTime'+index+data_local.availabilities[5].day) }}</span>
                  </div>

          <div v-if="index==0" class="vx-col w-.5/5">
              <vs-button type="border" class="ml-auto" @click="addNewTimeSlot(5)" :disabled="!validateForm">+</vs-button>
          </div>

          <div v-else class="vx-col w-.5/5">
              <vs-button type="border" color="danger" class="ml-auto" @click="removeTimeSlot(5, index, 'endTime'+index+data_local.availabilities[5].day)" :disabled="!validateForm">*</vs-button>
          </div>

              </div>
          </div>
        </div>
    </div>
    <!--enddd new dayy 5 -->

      <!-- new dayy 6 -->
    <div class="mt-4">
        <label class="vs-input--label">{{data_local.availabilities[6].day | capitalize}}</label>
        <div class="vx-row">
          <div class="vx-col w-full">
              <div class="vx-row mt-2" v-for="(availableTime, index) in data_local.availabilities[6].availableTimes" :key="index">
                  <div class="vx-col w-full md:w-2/5">
                    <flat-pickr @on-close="timeChangeEvent(6,index,0)" :ref="'beforeTarget'+index+data_local.availabilities[6].day" placeholder="Start Time" v-model="availableTime.startTime"  :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                     v-validate="{ required : availableTime.endTime ? true : false }" data-vv-as="start time" :name="'startTime'+index+data_local.availabilities[6].day" />
                    <span class="text-danger text-sm"  v-show="errors.has('startTime'+index+data_local.availabilities[6].day)">{{ errors.first('startTime'+index+data_local.availabilities[6].day) }}</span>
                  </div>
                  <div class="vx-col md:w-0.5/5 text-center">
                      -
                  </div>
                  <div class="vx-col w-full md:w-2/5">
                      <flat-pickr @on-close="timeChangeEvent(6,index,1)" ref="afterTarget" v-model="availableTime.endTime" placeholder="End Time" :config="{ enableTime: 'true', noCalendar: 'true',minuteIncrement:30, time_24hr: 'true'}" class="w-full"
                      v-validate="{ required : availableTime.startTime ? true : false, is_not: availableTime.startTime }"
                          data-vv-as="end time" :name="'endTime'+index+data_local.availabilities[6].day" />
                      <span class="text-danger text-sm"  v-show="errors.has('endTime'+index+data_local.availabilities[6].day)">{{ errors.first('endTime'+index+data_local.availabilities[6].day) }}</span>
                  </div>

          <div v-if="index==0" class="vx-col w-.5/5">
              <vs-button type="border" class="ml-auto" @click="addNewTimeSlot(6)" :disabled="!validateForm">+</vs-button>
          </div>

          <div v-else class="vx-col w-.5/5">
              <vs-button type="border" color="danger" class="ml-auto" @click="removeTimeSlot(6, index, 'endTime'+index+data_local.availabilities[6].day)" :disabled="!validateForm">*</vs-button>
          </div>

              </div>
          </div>
        </div>
    </div>
    <!--enddd new dayy 6 -->

  </div>

    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm">Save Changes</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'


export default {
  components: {
    flatPickr,
    vSelect
  },
  props: {
    data: {
      type: Object,
      required: true

    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
      timeZoneOptions: [
      { label: "Australia/Adelaide",  value: "Australia/Adelaide" },
      { label: "Australia/Brisbane",  value: "Australia/Brisbane" },
      { label: "Australia/Broken_Hill",  value: "Australia/Broken_Hill" },
      { label: "Australia/Currie",  value: "Australia/Currie" },
      { label: "Australia/Darwin",  value: "Australia/Darwin" },
      { label: "Australia/Eucla",  value: "Australia/Eucla" },
      { label: "Australia/Hobart",  value: "Australia/Hobart" },
      { label: "Australia/Lindeman",  value: "Australia/Lindeman" },
      { label: "Australia/Lord_Howe",  value: "Australia/Lord_Howe" },
      { label: "Australia/Melbourne",  value: "Australia/Melbourne" },
      { label: "Australia/Perth",  value: "Australia/Perth" },
      { label: "Australia/Sydney",  value: "Australia/Sydney" },
    ],
      error: [],
      availabilitiesTimeZone:'',
          availabilities: [
              {
                  day: 'sunday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }
                  ]
              },
              {
                  day: 'monday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              {
                  day: 'tuesday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              {
                  day: 'wednesday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              {
                  day: 'thursday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              {
                  day: 'friday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              {
                  day: 'saturday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              ]
    }
  },
  computed: {
    validateForm() {
      return true
    }
  },
  methods: {
    ...mapActions('tutor', ['updateTutorById']),
    async save_changes() {
      if(this.errors.items.length > 0){
        this.$vs.notify({
          title: 'Error',
          text: 'Validation Failed',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }
      let isValid = await this.$validator.validateAll()
      if(!isValid){
        this.$vs.notify({
          title: 'Error',
          text: 'Validation Failed',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }

      let availabilities = JSON.parse(JSON.stringify(this.data_local.availabilities))

      for(let i = 0; i<7; i++){
        for(let j = availabilities[i].availableTimes.length-1 ; j>=0; j--){
          if(availabilities[i].availableTimes[j].startTime == null){
            availabilities[i].availableTimes.splice(j, 1)
          }else{
            availabilities[i].availableTimes[j].startTime = availabilities[i].availableTimes[j].startTime.concat(':00')
            availabilities[i].availableTimes[j].endTime = availabilities[i].availableTimes[j].endTime.concat(':00')
          }
        }
      }
      let tutorData = {
        userType: 'tutor',
        profileCreatedBy: 'tutor',
        availabilities: availabilities,
        availabilitiesTimeZone: this.data_local.availabilitiesTimeZone,
      }
      let tutorId = this.$route.params.tutorId
      this.updateTutorById({tutorId, tutorData}).then(res => {
        this.$vs.notify({
          title: 'Update',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      }).catch(err => {
        this.$vs.notify({
          title: 'Update',
          text: 'Update Failed !',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      })


      // Here will go your API call for updating data
      // You can get data in "this.data_local"
    },
    reset_data() {
      this.data_local = Object.assign({}, this.data)
    },
    addNewTimeSlot(dayIndex){
      this.data_local.availabilities[dayIndex].availableTimes.push({startTime: null, endTime: null})
    },
    removeTimeSlot(dayIndex, timeIndex, errorField){
      this.errors.remove(errorField)
      this.data_local.availabilities[dayIndex].availableTimes.splice(timeIndex, 1)
    },
    timeChangeEvent(dayIndex, timeIndex, startEndTimeIndex){
      let startTime0 = ''
      let endTime0 = ''
      let data_local = this.data_local
      let self = this
      startTime0 = data_local.availabilities[dayIndex].availableTimes[timeIndex].startTime
      endTime0 = data_local.availabilities[dayIndex].availableTimes[timeIndex].endTime
      if(startTime0 == null || endTime0 == null){
        return false
      }
      startTime0 = parseInt(startTime0.replace(/:/g,''))
      endTime0 = parseInt(endTime0.replace(/:/g,''))
      if(startTime0 > endTime0){
          this.errors.add({
            field: 'endTime'+timeIndex+data_local.availabilities[dayIndex].day,
            msg: "End Time must greater than start time"
          });
      }
      for(let i = 0; i<data_local.availabilities[dayIndex].availableTimes.length; i++){
        let startTime1 = data_local.availabilities[dayIndex].availableTimes[i].startTime
        let endTime1 = data_local.availabilities[dayIndex].availableTimes[i].endTime
        if(startTime1 == null || endTime1 == null){
          continue
        }
        startTime1 = parseInt(startTime1.replace(/:/g,''))
        endTime1 = parseInt(endTime1.replace(/:/g,''))
        if(startTime1 > endTime1){
            this.errors.add({
              field: 'endTime'+i+data_local.availabilities[dayIndex].day,
              msg: "End Time must greater than start time"
            });
            continue
        }
        if(i != timeIndex){
          if(startTime0 != startTime1 && endTime0 != endTime1){
            if(startTime0 < startTime1){
              if(endTime0 <= startTime1){
                console.log('true')
              }
              else{
                this.errors.add({
                  field: 'endTime'+timeIndex+data_local.availabilities[dayIndex].day,
                  msg: "Overlapping Time Slots"
                });
                return false
              }
            }
            else if(startTime0 > startTime1){
              if(startTime0 >= endTime1){
                console.log('true')
              }
              else{
                this.errors.add({
                  field: 'endTime'+timeIndex+data_local.availabilities[dayIndex].day,
                  msg: "Overlapping Time Slots"
                });
                return false
              }

            }
          }
          else{
                this.errors.add({
                  field: 'endTime'+timeIndex+data_local.availabilities[dayIndex].day,
                  msg: "Overlapping Time Slots"
                });
            return false
          }
        }
      }
    }
  },
  mounted(){
    console.log(this.data_local)
    let availabilities = this.data_local.availabilities
    if(availabilities.length < 1){
      this.data_local.availabilities = this.availabilities
    }
    else{
      availabilities[0].day = 'sunday'
      availabilities[1].day = 'monday'
      availabilities[2].day = 'tuesday'
      availabilities[3].day = 'wednesday'
      availabilities[4].day = 'thursday'
      availabilities[5].day = 'friday'
      availabilities[6].day = 'saturday'
      for(let i = 0; i <= 6; i++){
        if(availabilities[i].availableTimes.length < 1){
          availabilities[i].availableTimes[0] = {startTime:null,endTime:null}
        }
      }
    }
  }
}
</script>
