<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="user-edit-tab-info">
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/3">

        <!-- Col Header -->
        <div class="flex items-end">
          <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
          <span class="leading-none font-medium">Qualifications
              <span v-if="data_local.qualifications.length < 1">
                - Unavailable
              </span></span>
        </div>

        <!-- Col Content -->
        <div v-for="(qualification, index) in data_local.qualifications" :key="index">
<vx-card class="mt-4">

          <vs-input class="w-full mt-4" label="Institute" v-model="qualification.institute" v-validate="'required'" data-vv-as="institute" :name="'institute'+index" data-vv-scope="basic"/>
          <span class="text-danger text-sm"  v-show="errors.has('basic.institute'+index)">{{ errors.first('basic.institute'+index) }}</span>

          <vs-input class="w-full mt-4" label="Title" v-model="qualification.title" v-validate="'required'" data-vv-as="title" :name="'title'+index" data-vv-scope="basic"/>
          <span class="text-danger text-sm"  v-show="errors.has('basic.title'+index)">{{ errors.first('basic.title'+index) }}</span>

          <vs-input class="w-full mt-4" label="Subject" v-model="qualification.subject" v-validate="'required'" :name="'subject'+index" data-vv-as="subject" data-vv-scope="basic"/>
          <span class="text-danger text-sm"  v-show="errors.has('basic.subject'+index)">{{ errors.first('basic.subject'+index) }}</span>

          <vs-input class="w-full mt-4" label="ATAR" v-model="qualification.atar" v-validate="'required'" :name="'atar'+index" data-vv-as="atar" data-vv-scope="basic"/>
          <span class="text-danger text-sm"  v-show="errors.has('basic.atar'+index)">{{ errors.first('basic.atar'+index) }}</span>

          <div class="mt-4">
            <label class="text-sm">Started At</label>
            <flat-pickr v-model="qualification.startedAt" :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', maxDate: new Date(), altInputClass:'w-full flatpickr-input' }" class="w-full" v-validate="'required'" :name="'startedAt'+index" data-vv-validate-on="blur" data-vv-scope="basic" data-vv-as="started at"/>
            <span class="text-danger text-sm"  v-show="errors.has('basic.startedAt'+index)">{{ errors.first('basic.startedAt'+index) }}</span>
          </div>

          <div class="mt-4">
            <label class="text-sm">Ended At</label>
            <flat-pickr v-model="qualification.endedAt" :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', minDate: new Date(), altInputClass:'w-full flatpickr-input' }" class="w-full" :name="'endedAt'+index" data-vv-scope="basic"/>
            <span class="text-danger text-sm"  v-show="errors.has('endedAt'+index)">{{ errors.first('endedAt'+index) }}</span>
          </div>

          <vs-button color="danger" class="ml-auto mt-4" type="border" @click="remove_qualification(index)">- Remove Qualification</vs-button>

</vx-card>
        </div>
      </div>

      <!-- Address Col -->
      <div class="vx-col w-full md:w-1/3">

          <!-- Col Header -->
          <div class="flex items-end md:mt-0 mt-base">
            <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Work Experiences
              <span v-if="data_local.workExperiences.length < 1">
                - Unavailable
              </span>
            </span>
          </div>

          <!-- Col Content -->
          <div v-for="(workExperience, index) in data_local.workExperiences" :key="index">
            <vx-card class="mt-4">
            <vs-input class="w-full mt-4" label="Title" v-model="workExperience.title" v-validate="'required'" :name="'titleW'+index" data-vv-scope="basic" data-vv-as="title"/>
            <span class="text-danger text-sm"  v-show="errors.has('basic.titleW'+index)">{{ errors.first('basic.titleW'+index) }}</span>

            <vs-input class="w-full mt-4" label="Company" v-model="workExperience.company" v-validate="'required'" :name="'companyW'+index" data-vv-scope="basic" data-vv-as="company"/>
            <span class="text-danger text-sm"  v-show="errors.has('basic.companyW'+index)">{{ errors.first('basic.companyW'+index) }}</span>

          <div class="mt-4">
            <label class="text-sm">Started At</label>

            <flat-pickr v-model="workExperience.startedAt"
            :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', maxDate: new Date(), altInputClass:'w-full flatpickr-input' }"
            class="w-full"
            v-validate="'required'"
            :name="'startedAtW'+index"
            data-vv-as="started at"
            data-vv-validate-on="input|blur|on-close"
            data-vv-scope="basic"/>
            <span class="text-danger text-sm"  v-show="errors.has('basic.startedAtW'+index)">{{ errors.first('basic.startedAtW'+index) }}</span>
          </div>

          <div class="mt-4">
            <label class="text-sm">Ended At</label>
            <flat-pickr v-model="workExperience.endedAt" :config="{altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', minDate: new Date(), altInputClass:'w-full flatpickr-input' }" class="w-full" v-validate="''" :name="'endedAtW'+index" />
            <span class="text-danger text-sm"  v-show="errors.has('endedAtW'+index)">{{ errors.first('endedAtW'+index) }}</span>
          </div>
          <vs-button color="danger" class="ml-auto mt-4" type="border" @click="remove_work_experience(index)">- Remove Work Experience</vs-button>
            </vx-card>
          </div>
      </div>

      <!-- Address Col -->
      <div class="vx-col w-full md:w-1/3">

          <!-- Col Header -->
          <div class="flex items-end md:mt-0 mt-base">
            <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Licenses
              <span v-if="data_local.licenses.length < 1">
                - Unavailable
              </span>
            </span>
          </div>

          <!-- Col Content -->
          <div v-for="(license, index) in data_local.licenses" :key="index">
            <vx-card class="mt-4">
            <vs-input class="w-full mt-4" label="Title" v-model="license.title" v-validate="'required'" :name="'titlel'+index" data-vv-scope="basic" data-vv-as="title"/>
            <span class="text-danger text-sm"  v-show="errors.has('basic.titlel'+index)">{{ errors.first('basic.titlel'+index) }}</span>

            <vs-input class="w-full mt-4" label="Issuing Organization" v-model="license.issuingOrganization" v-validate="'required'" :name="'issuingOranization'+index" data-vv-scope="basic" data-vv-as="issuing oranization"/>
            <span class="text-danger text-sm"  v-show="errors.has('basic.issuingOranization'+index)">{{ errors.first('basic.issuingOranization'+index) }}</span>

          <div class="mt-4">
            <label class="text-sm">Issued At</label>
            <flat-pickr v-model="license.issuedAt"
            :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', maxDate: new Date(), altInputClass:'w-full flatpickr-input' }"
            class="w-full"
            v-validate="'required'"
            :name="'issuedAt'+index"
            data-vv-as="issued at"
            data-vv-validate-on="input|blur|on-close"
            data-vv-scope="basic"/>
            <span class="text-danger text-sm"  v-show="errors.has('basic.issuedAt'+index)">{{ errors.first('basic.issuedAt'+index) }}</span>
          </div>

          <div class="mt-4">
            <label class="text-sm">Expired At</label>
            <flat-pickr v-model="license.expiredAt" :config="{altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', minDate: new Date(), altInputClass:'w-full flatpickr-input' }" class="w-full" v-validate="''" :name="'expiredAt'+index" />
            <span class="text-danger text-sm"  v-show="errors.has('expiredAt'+index)">{{ errors.first('expiredAt'+index) }}</span>
          </div>
          <vs-button color="danger" class="ml-auto mt-4" type="border" @click="remove_license(index)">- Remove License</vs-button>
            </vx-card>
          </div>
      </div>
    </div>

 <vs-popup classContent="popup-example"  title="Add Qualification" :active.sync="popupActivo2">
    <vs-input class="w-full mt-4" label="Institute" v-model="newQualification.institute" v-validate="'required'" data-vv-as="institute" data-vv-validate-on="blur" name="institute" />
          <span class="text-danger text-sm"  v-show="errors.has('institute')">{{ errors.first('institute') }}</span>

          <vs-input class="w-full mt-4" label="Title" v-model="newQualification.title" v-validate="'required'" data-vv-as="title" data-vv-validate-on="blur" name="title" />
          <span class="text-danger text-sm"  v-show="errors.has('title')">{{ errors.first('title') }}</span>

          <vs-input class="w-full mt-4" label="Subject" v-model="newQualification.subject" v-validate="'required'" name='subject' data-vv-validate-on="blur" data-vv-as="subject" />
          <span class="text-danger text-sm"  v-show="errors.has('subject')">{{ errors.first('subject') }}</span>

          <vs-input class="w-full mt-4" label="ATAR" v-model="newQualification.atar" v-validate="'required'" name="atar" data-vv-as="atar" data-vv-validate-on="blur"/>
          <span class="text-danger text-sm"  v-show="errors.has('atar')">{{ errors.first('atar') }}</span>

          <div class="mt-4">
            <label class="text-sm">Started At</label>
            <flat-pickr v-model="newQualification.startedAt" :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', maxDate: new Date(), altInputClass:'w-full flatpickr-input' }" class="w-full" v-validate="'required'" name="startedAt" data-vv-validate-on="blur"/>
            <span class="text-danger text-sm"  v-show="errors.has('startedAt')">{{ errors.first('startedAt') }}</span>
          </div>

          <div class="mt-4">
            <label class="text-sm">Ended At</label>
            <flat-pickr v-model="newQualification.endedAt" :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', minDate: new Date(), altInputClass:'w-full flatpickr-input' }" class="w-full" v-validate="''" name='endedAt' />
            <span class="text-danger text-sm"  v-show="errors.has('endedAt')">{{ errors.first('endedAt') }}</span>
          </div>
          <vs-button color="success" class="ml-auto mt-4" @click="add_qualification"> Add Qualification</vs-button>

  </vs-popup>

 <vs-popup classContent="popup-example"  title="Add Work Experience" :active.sync="popupActivo3">
    <vs-input class="w-full mt-4" label="Title" v-model="newWorkExperience.title" v-validate="'required'" data-vv-as="title" data-vv-validate-on="blur" name="titlew" />
          <span class="text-danger text-sm"  v-show="errors.has('titlew')">{{ errors.first('titlew') }}</span>

          <vs-input class="w-full mt-4" label="Company" v-model="newWorkExperience.company" v-validate="'required'" data-vv-as="company" data-vv-validate-on="blur" name="company" />
          <span class="text-danger text-sm"  v-show="errors.has('company')">{{ errors.first('company') }}</span>

          <div class="mt-4">
            <label class="text-sm">Started At</label>
            <flat-pickr v-model="newWorkExperience.startedAt" :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', maxDate: new Date(), altInputClass:'w-full flatpickr-input'}" class="w-full" v-validate="'required'" data-vv-validate-on="blur" data-vv-as="started at" name="startedAtw" />
            <span class="text-danger text-sm"  v-show="errors.has('startedAtw')">{{ errors.first('startedAtw') }}</span>
          </div>

          <div class="mt-4">
            <label class="text-sm">Ended At</label>
            <flat-pickr v-model="newWorkExperience.endedAt" :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', minDate: new Date(), altInputClass:'w-full flatpickr-input' }" class="w-full" v-validate="''" data-vv-as="ended at" name='endedAtw' />
            <span class="text-danger text-sm"  v-show="errors.has('endedAtw')">{{ errors.first('endedAtw') }}</span>
          </div>
          <vs-button color="success" class="ml-auto mt-4" @click="add_work_experience"> Add Work Experience</vs-button>

  </vs-popup>
 <vs-popup classContent="popup-example"  title="Add License" :active.sync="popupActivo4">
    <vs-input class="w-full mt-4" label="Title" v-model="newLicenses.title" v-validate="'required'" data-vv-as="title" data-vv-validate-on="blur" name="titlel" />
          <span class="text-danger text-sm"  v-show="errors.has('titlel')">{{ errors.first('titlel') }}</span>

          <vs-input class="w-full mt-4" label="Issuing Organization" v-model="newLicenses.issuingOrganization" v-validate="'required'" data-vv-as="issuing organization" data-vv-validate-on="blur" name="issuingOrganization" />
          <span class="text-danger text-sm"  v-show="errors.has('issuingOrganization')">{{ errors.first('issuingOrganization') }}</span>

          <div class="mt-4">
            <label class="text-sm">Issued At</label>
            <flat-pickr v-model="newLicenses.issuedAt" :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', maxDate: new Date(), altInputClass:'w-full flatpickr-input'}" class="w-full" v-validate="'required'" data-vv-validate-on="blur" data-vv-as="issued at" name="issuedAt" />
            <span class="text-danger text-sm"  v-show="errors.has('issuedAt')">{{ errors.first('issuedAt') }}</span>
          </div>

          <div class="mt-4">
            <label class="text-sm">Expired At</label>
            <flat-pickr v-model="newLicenses.expiredAt" :config="{ altInput:true, altFormat: 'd / m / Y', dateFormat: 'Y-m-d', minDate: new Date(), altInputClass:'w-full flatpickr-input' }" class="w-full" v-validate="''" data-vv-as="expired at" name='expiredAt' />
            <span class="text-danger text-sm"  v-show="errors.has('expiredAt')">{{ errors.first('expiredAt') }}</span>
          </div>
          <vs-button color="success" class="ml-auto mt-4" @click="add_license"> Add License</vs-button>

  </vs-popup>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" type="border" @click="open_add_qualification_popup">+ Add Qualification</vs-button>
          <vs-button class="ml-4 mt-2" type="border" @click="open_add_work_experiences_popup">+ Add Work Experience</vs-button>
          <vs-button class="ml-4 mt-2" type="border" @click="open_add_licenses_popup">+ Add License</vs-button>
          <vs-button class="ml-4 mt-2" @click="save_changes">Save Changes</vs-button>
          <!-- <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data">Reset</vs-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'

export default {
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      data_local: JSON.parse(JSON.stringify(this.data)),
      popupActivo2: false,
      popupActivo3: false,
      popupActivo4: false,
      newQualification:{
          institute: '',
          title: '',
          subject: '',
          startedAt: '',
          endedAt: ''
      },
      newWorkExperience:{
          company: '',
          title: '',
          startedAt: '',
          endedAt: ''
      },
      newLicenses:{
          issuingOrganization: '',
          title: '',
          issuedAt: '',
          expiredAt: ''
      },
      langOptions: [
        { label: "English",  value: "english"  },
        { label: "Spanish",  value: "spanish"  },
        { label: "French",   value: "french"   },
        { label: "Russian",  value: "russian"  },
        { label: "German",   value: "german"   },
        { label: "Arabic",   value: "arabic"   },
        { label: "Sanskrit", value: "sanskrit" },
      ],
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any()
    }
  },
  methods: {
    ...mapActions('tutor', ['updateTutorById']),
    open_add_qualification_popup(){
      this.popupActivo2 = true
      this.newQualification = {
          institute: '',
          title: '',
          subject: '',
          startedAt: 'x',
          endedAt: ''
      }
    },
    open_add_work_experiences_popup(){
      this.popupActivo3 = true,
      this.newWorkExperience = {
          company: '',
          title: '',
          startedAt: 'y',
          endedAt: ''
      }
    },
    open_add_licenses_popup(){
      this.popupActivo4 = true,
      this.newLicenses = {
          issuingOrganization: '',
          title: '',
          issuedAt: 'y',
          expiredAt: ''
      }
    },
    add_qualification(){
      this.data_local.qualifications.push(this.newQualification)
      this.popupActivo2 = false
    },
    add_work_experience(){
      this.data_local.workExperiences.push(this.newWorkExperience)
      this.popupActivo3 = false
    },
    add_license(){
      this.data_local.licenses.push(this.newLicenses)
      this.popupActivo4 = false
    },
    remove_qualification(index){
      this.data_local.qualifications.splice(index, 1)
    },
    remove_work_experience(index){
      this.data_local.workExperiences.splice(index, 1)
    },
    remove_license(index){
      this.data_local.licenses.splice(index, 1)
    },
    async save_changes() {
      // if(!this.validateForm) return
      let isValid = await this.$validator.validateAll('basic')
      if(!isValid){
        this.$vs.notify({
          title: 'Error',
          text: 'Validation Failed',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }
      let tutorId = this.$route.params.tutorId
      let tutorData = {
        userType: 'tutor',
        profileCreatedBy: 'tutor',
        qualifications: this.data_local.qualifications,
        workExperiences: this.data_local.workExperiences,
        licenses: this.data_local.licenses
      }
      this.updateTutorById({tutorId, tutorData}).then(res => {
        this.$vs.notify({
          title: 'Update',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      }).catch(err => {
        this.$vs.notify({
          title: 'Update',
          text: 'Update Failed !',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      })
      // Here will go your API call for updating data
      // You can get data in "this.data_local"
    },
    reset_data() {
      this.data_local = Object.assign({}, this.data)
    }
  },
}
</script>
